import { useEffect, useState } from "react";
import { Link } from "gatsby";
import { css, cx } from "@linaria/core";

import { Meta, Layout, Section } from "~/ui";
import { useStoredQuestions } from "~/utils";
import { ContactForm, Module } from "~/components";

import { Routes } from "~/models";
import type { ModuleStatus } from "~/types";

import gettingReadyImg from "~/assets/images/img-getting-ready.png";

export default function GettingReady(): JSX.Element | null {
  const { completedModules } = useStoredQuestions();

  const [moduleOneStatus, setModuleOneStatus] =
    useState<ModuleStatus>("available");
  const [moduleTwoStatus, setModuleTwoStatus] =
    useState<ModuleStatus>("locked");
  const [moduleThreeStatus, setModuleThreeStatus] =
    useState<ModuleStatus>("locked");
  const [moduleFourStatus, setModuleFourStatus] =
    useState<ModuleStatus>("locked");

  useEffect(() => {
    const {
      1: isModuleOneComplete,
      2: isModuleTwoComplete,
      3: isModuleThreeComplete,
      4: isModuleFourComplete,
    } = completedModules || {};

    setModuleOneStatus(isModuleOneComplete ? "completed" : "available");
    setModuleTwoStatus(
      isModuleTwoComplete
        ? "completed"
        : isModuleOneComplete
        ? "available"
        : "locked"
    );
    setModuleThreeStatus(
      isModuleThreeComplete
        ? "completed"
        : isModuleTwoComplete
        ? "available"
        : "locked"
    );
    setModuleFourStatus(
      isModuleFourComplete
        ? "completed"
        : isModuleThreeComplete
        ? "available"
        : "locked"
    );
  }, [completedModules]);

  const moduleList = [
    {
      moduleNumber: 1,
      moduleStatus: moduleOneStatus,
      moduleTitle: "Introduction to Volunteering",
      moduleContent:
        "Learn what is volunteering, why do people volunteer and your rights and responsibilities when volunteering",
    },
    {
      moduleNumber: 2,
      moduleStatus: moduleTwoStatus,
      moduleTitle: "Types of Volunteering",
      moduleContent:
        "Discover all the types of volunteering with examples and how to find your own reason to volunteer",
    },
    {
      moduleNumber: 3,
      moduleStatus: moduleThreeStatus,
      moduleTitle: "Finding volunteering opportunities",
      moduleContent:
        "Searching, creating a volunteer profile and what checks and clearances you might need",
    },
    {
      moduleNumber: 4,
      moduleStatus: moduleFourStatus,
      moduleTitle: "Job Readiness",
      moduleContent:
        "Getting into volunteering is a way to build the skills, knowledge and confidence to become workplace ready",
    },
  ];

  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row mb-5">
          <div className="col-12 col-lg-7 mb-5">
            <h1 className="d-flex flex-column text-primary">
              Getting
              <span className="display-1">Ready</span>
            </h1>
            <p className="lead mb-5">
              Are you excited about starting your volunteering journey but are
              not sure where to start? We have developed resources to help you
              work through the steps to take to get volunteer ready.
            </p>
            <div className="w-md-75 mx-auto text-center">
              <blockquote>
                <p>
                  "Volunteering has helped me build up my confidence as well as
                  communication skills with others along with making new
                  friends"
                </p>
                - Cadet at Bunbury Volunteer Bushfire Brigade
              </blockquote>
            </div>
          </div>
          <div className="col-12 col-lg-4 offset-lg-1 text-center mb-5">
            <img
              src={gettingReadyImg}
              alt="Two youth volunteers wearing VWA Society t shirts designed by Oli"
              className="img-fluid mt-lg-n5"
              width={300}
              height={300}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="position-relative d-inline-block p-4">
              <span
                className="position-relative font-weight-normal text-success"
                style={{ zIndex: 1 }}
              >
                Free volunteer ready course
              </span>
              <span className="position-absolute-0 strokes stroke-1" />
            </h2>
          </div>
          {moduleList.map((module) => (
            <div key={module.moduleNumber} className="col-12 col-lg-6 mb-4">
              <ModuleComponent {...module} />
            </div>
          ))}
        </div>
      </Section>

      <Section
        bgColor="offWhite"
        baseBgColorTop="creamGradientEnd"
        roundedCorners={{ "top-right": true }}
      >
        <div id="faqs" className="mb-4 mb-lg-5">
          <h3 className="d-flex flex-column text-primary">
            <span className="h1">Still have</span>
            <span className="display-1">Questions?</span>
          </h3>
        </div>
        {faq?.map(({ title, content }, idx) => (
          <article key={idx} className="mb-5">
            <h4 className="h3 text-success font-weight-normal mb-2">{title}</h4>
            <div>{content}</div>
          </article>
        ))}
      </Section>

      <Section bgColor="violet200">
        <div className="text-center">
          <h2 className="h4 font-weight-normal text-primary mb-1">
            Have another question?
          </h2>
          <p className="mb-5">
            If you can&apos;t find the answer to your question, we&apos;d be
            happy to help.
          </p>
          <ContactForm />
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Getting Ready" url={Routes.GettingReady} />;
};

interface ModuleComponentProps {
  moduleNumber: number;
  moduleStatus: ModuleStatus;
  moduleTitle: string;
  moduleContent: string;
}

const ModuleComponent = (props: ModuleComponentProps) => {
  if (props.moduleStatus === "locked") {
    return (
      <Module
        id={props.moduleNumber}
        status={props.moduleStatus}
        title={props.moduleTitle}
        content={props.moduleContent}
      />
    );
  }

  return (
    <Link
      to={Routes.Modules}
      state={{ moduleSelection: props.moduleNumber }}
      className={cx(
        "text-reset",
        css`
          &:hover {
            text-decoration: none;
          }
        `
      )}
    >
      <Module
        id={props.moduleNumber}
        status={props.moduleStatus}
        title={props.moduleTitle}
        content={props.moduleContent}
      />
    </Link>
  );
};

const faq = [
  {
    title: "How do I start volunteering?",
    content: (
      <>
        Check out{" "}
        <Link to={Routes.Opportunities}> yvolunteer.org.au opportunities</Link>{" "}
        and search by your interests to find experiences near you. See what
        volunteering roles are available,{" "}
        <Link to={Routes.Stories}>stories</Link> of how other youth volunteers
        got started and what you can do to{" "}
        <Link to={Routes.GettingReady}>get&nbsp;ready</Link> for volunteering.
      </>
    ),
  },
  {
    title: "How much time will I be expected to give?",
    content: (
      <>
        Every volunteering opportunity is different and every organisation will
        have different requirements. In yvolunteer.org.au you can choose
        positions at the time commitment that suits you, for example once-off or
        ongoing. Some roles might be looking for a volunteer for an event, a
        couple hours a month, or whenever you have time. Roles can be in-person
        or online and may be flexible to fit around your other commitments.
      </>
    ),
  },
  {
    title: "Will there be support for me as a young volunteer?",
    content: (
      <>
        <p>
          Ask about any mentoring or support while you get used to the volunteer
          role and then build up responsibilities, as your confidence and skills
          develop. It is okay to make and talk about any mistakes, and to see
          this as a learning experience.
        </p>
        <p>
          Volunteering is social! If you are nervous about starting a volunteer
          role, you can ask friends or family to join with you and share the
          experience. Volunteering can help with your personal and career
          development and also help develop transferrable skills like IT or
          communication skills, which are sought after in the workplace.
          Volunteering also helps you gain work experience, meet new people or
          contacts and can increase self-confidence. Volunteer organisations can
          provide recognition for service and also be referees for job
          applications.
        </p>
      </>
    ),
  },
  {
    title: "What if I can't continue with the voluntary position?",
    content: (
      <>
        Before you start, ask about a trial period to see if the role is a good
        fit for you and the organisation. Be open with the organisation you
        volunteer with - if you feel that you are no longer in a position to
        volunteer, give the organisation some notice to allow them to find a
        replacement. It's worth remembering too, that if a volunteering activity
        doesn't turn out as you'd expected, there are other opportunities you
        can try.
      </>
    ),
  },
  {
    title:
      "Does an organisation need to be incorporated to involve volunteers?",
    content: (
      <>
        <p>
          No. Informal volunteering groups are often established to fill a short
          term or emergency need, although some may continue to operate for a
          number of years. Informal groups are often effective at responding to
          issues in local communities and neighbourhoods.
        </p>
        <p>
          It is important to understand that when you volunteer for an
          unincorporated organisation, you cannot access civil protections for
          volunteers under WA legislation, the organisations may lack policies
          and procedures and training, and almost certainly will not hold
          Personal Accident Insurance for volunteers. For this reason, they are
          not eligible for membership of Volunteering WA.
        </p>
      </>
    ),
  },
];
